import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { trackOwnerManualFolderClick } from 'tw-oi-core/services/analytics'

import { MESSAGE, ROUTE, UI } from '../config'
import {
  OWNERS_MANUAL_TITLE, getPublicationsForVehicle, getArticlesWithTags, getContentByTag, getUniqueTags,
  compareNumberedStrings, tagToRenderItem
} from '../extras/utils'

import ScreenHead from '../components/ScreenHead'
import ScreenContainer from '../components/ScreenContainer'
import ContentList from '../components/ContentList'
import Icon from '../components/Icon'
import Media from '../components/Media'
import StickyBar from '../components/StickyBar'
import BreadCrumb from '../components/BreadCrumb'

import '../styles/Topics.scss'
import OwnersManualPdf from '../components/OwnersManualPdfs'

const ArticlesTags = ({articles, currentYear, currentModel, currentBrand, match, baseRoute, ownersManualPdfs}) => {

  const [items, breadcrumbs] = useMemo(() => {
    const tag = decodeURIComponent(match.params.tag)
    const tags = getUniqueTags(tag ? getContentByTag(tag, articles) : articles)

    // Skip a special type of tag (Owner's Manual) and order tags A-Z
    const articleTags = tags
      .filter(x => x !== OWNERS_MANUAL_TITLE).sort(compareNumberedStrings).map(tagToRenderItem)
      .map(item => ({
        ...item,
        link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.ARTICLES}/${encodeURIComponent(item.title)}`,
        icon: <Icon type="folder" />
      }))

    const breadcrumbs = [
      {title: 'Browse', link: `${baseRoute}${ROUTE.BROWSE}`},
      {title: tag, link: ''}
    ]

    return [articleTags, breadcrumbs]
  }, [match])

  return (
    <div className="Articles-tags Topics">
      <ScreenHead
        back={`${baseRoute}${ROUTE.BROWSE}`}
        backTitle={UI.BROWSE_TITLE}
      >
        <em>{currentYear} {currentBrand}</em>
        <span>{currentModel}</span>
      </ScreenHead>

      <Media type="mobile">
        <div className="topics-subtitle">{decodeURIComponent(match.params.tag) || 'Articles'}</div>
      </Media>

      <div className="screen-content">
        <Media type="desktop">
          <StickyBar className="sticky-topics-bar">
            <div className="topics-bar">
              <BreadCrumb items={breadcrumbs}/>
              <OwnersManualPdf model={currentModel} ownersManualPdfs={ownersManualPdfs} />
            </div>
          </StickyBar>
        </Media>

        <ScreenContainer currentUrl={match.url}>
          <div className="topics">
            <ContentList
              topics={items}
              emptyText={MESSAGE.EMPTY_TOPICS}
              baseRoute={match.url}
              onClick={item => trackOwnerManualFolderClick(item.title)}
            />
          </div>
        </ScreenContainer>
      </div>
    </div>
  )
}

ArticlesTags.propTypes = {
  articles: PropTypes.array,
  currentYear: PropTypes.string,
  currentModel: PropTypes.string,
  currentBrand: PropTypes.string,
  match: PropTypes.object.isRequired,
  baseRoute: PropTypes.string,
  isDesktop: PropTypes.bool,
  ownersManualPdfs: PropTypes.list,
}

function mapStateToProps({ contents: { publications } , vehicle, user }) {
  const pubs = getPublicationsForVehicle(publications.toJS())
  const articles = getArticlesWithTags(pubs)
  const { currentYear, currentModel, currentBrand } = vehicle
  const { media: userMedia } = user

  return { articles, currentYear, currentModel, currentBrand, isDesktop: userMedia.isDesktop }
}

export default connect(mapStateToProps)(ArticlesTags)
